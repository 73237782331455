<template>
    <v-card class="mb-12">
        <v-container v-if="loader">
            <v-col cols="12" sm="12" md="12" class="text-center">
                <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
            </v-col>
        </v-container>
        <v-container v-else-if="!pdfh && secciones.length >0 ">
            <v-spacer></v-spacer>
            <v-row v-if="item.historico">
              <v-col cols="12" sm="12" md="4">
                <v-menu v-model="menuFechaDesde" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field required outlined dense v-model="fechaDesde" label="Fecha desde" append-icon="event" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker locale="ES" v-model="fechaDesde" @input="menuFechaDesde = false" :allowed-dates="val => new Date(val.replace(/-+/g, '/')).getTime() < new Date(this.datosPaciente.tblCitaFacturaciones[0].cifaFecha).getTime()"  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-btn color="info" @click="cargarDatos" dark>
                  <v-icon>search</v-icon>
              </v-btn>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="pdf(false)" dark>
                <v-icon>mdi-file-pdf-box</v-icon> PDF
            </v-btn>
            <v-btn color="primary ml-5" @click.stop="correo=item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeEmail;pdfh=item" dark>
                <v-icon>mdi-email-outline</v-icon> Enviar
            </v-btn>
            <template>
                <v-row v-for="seccion in  secciones" :key="seccion.hiseId">
                    <v-col cols="12" sm="12" md="12" class="primary--text">
                        <h3>{{seccion.hiseNombre}}</h3>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" :key="seccion.hiseId" class="primary--text" v-if="seccion.hiseHistorico">
                        {{datosPaciente.tblCitaFacturaciones[0].cifaFecha}}
                    </v-col>
                        <v-col v-for="c in  seccion.campos" cols="12" sm="12" :md="c.tblConfHistoriaseccione.hiseCol" :key="'c'+c.cihfId">
                            <div v-if="c.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'LISTA' || c.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'SI/NO' && seccion.hiseCalculado">
                                <span class="black--text">{{c.tblConfHistoriaseccione.hiseNombre}}</span>
                                <span class="grey--text caption pa-5">{{`${getValues(c.tblConfHistoriaseccione.hiseValores, c.cihfValor)}(${c.cihfValor})`}}</span>
                            </div>
                            <div v-else>
                                <span class="black--text">{{c.tblConfHistoriaseccione.hiseNombre}}</span>
                                <span class="grey--text caption pa-5">{{c.cihfValor}}</span>
                            </div>
                        </v-col>
                    <template v-if="seccion.hiseHistorico">

                        <template v-for="(c,index) in  historial.filter(e => e.tblConfHistoriaseccione.hiseIdDependencia == seccion.hiseId)">

                            <v-col cols="12" sm="12" md="12" class="primary--text" v-if="index == 0 || historial.filter(e => e.tblConfHistoriaseccione.hiseIdDependencia == seccion.hiseId)[(index > 0 )?index - 1:index].tblCitaFacturacione.cifaId > c.tblCitaFacturacione.cifaId " :key="'hf'+index  +c.tblCitaFacturacione.cifaId">
                                {{c.tblCitaFacturacione.cifaFecha }}
                            </v-col>
                            <v-col cols="12" sm="12" :md="c.hiseCol" :key="'hc'+c.tblCitaFacturacione.cifaId + index">

                                <div v-if="c.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'LISTA' || c.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'SI/NO' && seccion.hiseCalculado">
                                    <span class="black--text">{{c.hiseNombre}}</span>
                                    <span class="grey--text caption pa-5">{{`${getValues(c.tblConfHistoriaseccione.hiseValores, c.cihfValor)}(${c.cihfValor})`}}</span>
                                </div>
                                <div v-else>
                                    <span class="black--text">{{c.tblConfHistoriaseccione.hiseNombre}}</span>
                                    <span class="grey--text caption pa-5">{{c.cihfValor}}</span>
                                </div>

                            </v-col>

                        </template>
                    </template>
                </v-row>
                <v-row v-if="diagnosticos.length > 0">
                    <v-col cols="12" sm="12" md="12" class="primary--text">
                        <h3>Diagnosticos</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in diagnosticos" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">
                                                    <v-col cols="12" sm="12" md="6">
                                                        <p>{{item.cie10.comaNombrelargo}} </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="3">
                                                        <p>{{item.clase.comaNombrelargo}} </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="2">
                                                        <p>{{ item.diagnostico.comaNombrelargo}} </p>
                                                    </v-col>

                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_PRO' || e.orden.comaNombrecorto == 'TP_CUPS_TS').length > 0">
                    <v-col cols="12" sm="12" md="12" class="primary--text">
                        <h3>Ordones realizadas</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_PRO' || e.orden.comaNombrecorto == 'TP_CUPS_TS')" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">
                                                    <v-col cols="12" sm="12" md="6">
                                                        <p>
                                                            {{item.tblConfCups[0].cocuNombre}}
                                                        </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="5">
                                                        <p> {{ item.orden.comaNombrelargo}} </p>
                                                    </v-col>

                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-divider light class="mx-1"></v-divider>
                                                        <p> {{item.ciorObservacion}}</p>
                                                    </v-col>

                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="ordenes.filter(e => e.orden.comaNombrecorto != 'TP_ORD_PRO' && e.orden.comaNombrecorto != 'TP_CUPS_TS').length > 0">
                    <v-col cols="12" sm="12" md="12" class="primary--text" v-if="ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_LAB' ).length > 0">
                        <h3>Ordones de laboratorio</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_LAB' )" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">
                                                    <v-col cols="12" sm="12" md="6">
                                                        <p>
                                                            {{item.tblConfCups[0].cocuNombre}}
                                                        </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="3">
                                                        <p> {{ item.orden.comaNombrelargo}} </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="2">

                                                        <p> {{item.ciorObservacion}}</p>
                                                    </v-col>

                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="primary--text" v-if="ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_IMG' ).length > 0">
                        <h3>Ordones de imagenes diagnosticas</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_IMG' )" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">
                                                    <v-col cols="12" sm="12" md="6">
                                                        <p>
                                                            {{item.tblConfCups[0].cocuNombre}}
                                                        </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="3">
                                                        <p> {{ item.orden.comaNombrelargo}} </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="2">

                                                        <p> {{item.ciorObservacion}}</p>
                                                    </v-col>

                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="primary--text" v-if="ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_SER' ).length > 0">
                        <h3>Ordones de servicio</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_SER' )" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">
                                                    <v-col cols="12" sm="12" md="6">
                                                        <p>
                                                            {{item.tblConfCups[0].cocuNombre}}
                                                        </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="5">
                                                        <p> {{ item.orden.comaNombrelargo}} </p>
                                                    </v-col>

                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-divider light class="mx-1"></v-divider>
                                                        <p> {{item.ciorObservacion}}</p>
                                                    </v-col>

                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="primary--text" v-if="ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_CIR' ).length > 0">
                        <h3>Ordones de cirugia</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in ordenes.filter(e => e.orden.comaNombrecorto == 'TP_ORD_CIR')" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">
                                                    <v-col cols="12" sm="12" md="6">
                                                        <p>
                                                            {{item.tblConfCups[0].cocuNombre}}
                                                        </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="3">
                                                        <p> {{ item.orden.comaNombrelargo}} </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="2">
                                                        <p> {{item.ciorObservacion}}</p>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="recetarios.length > 0">
                    <v-col cols="12" sm="12" md="12" class="primary--text">
                        <h3>Recetarios</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in recetarios" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">

                                                    <v-col cols="12" sm="12" md="5">
                                                        <p>
                                                            {{item.tblMediMedicamento.mediNombre}}
                                                        </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="2">

                                                        <p>Frecuencia de horas {{item.cireFrecuenciahoras}}</p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="2">

                                                        <p>Duracion en dias {{item.cireDuraciondias}} </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="2">

                                                        <p>Cantidad {{item.cireCantidadprescrita}} </p>
                                                    </v-col>

                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-divider light class="mx-1"></v-divider>
                                                        <p>{{item.cireDetalles}} </p>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="remisiones.length > 0">
                    <v-col cols="12" sm="12" md="12" class="primary--text">
                        <h3>Remisiones</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in remisiones" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">
                                                    <v-col cols="12" sm="12" md="4">
                                                        <label>
                                                            {{item.tblConfCup.cocuNombre}}
                                                        </label>
                                                    </v-col>
                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="6">

                                                        <label> {{item.cirmObservacion}}</label>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="incapacidades.length > 0">
                    <v-col cols="12" sm="12" md="12" class="primary--text">
                        <h3>Incapacidades</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in incapacidades" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">

                                                    <v-col cols="12" sm="12" md="4">
                                                        <p>
                                                            Fecha inicio {{item.ciceFechainicio}}
                                                        </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="4">

                                                        <p> Fecha final {{ item.ciceFechafinal}} </p>
                                                    </v-col>
                                                    <v-divider light class="mx-1" vertical></v-divider>
                                                    <v-col cols="12" sm="12" md="3">

                                                        <p> Dias {{item.ciceDias}}</p>
                                                    </v-col>

                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="certificados.length > 0">
                    <v-col cols="12" sm="12" md="12" class="primary--text">
                        <h3>Certificados</h3>
                        <v-card class="pa-2 " color="grey lighten-2" outlined tile>
                            <v-container>
                                <v-row justify="space-around">
                                    <v-col cols="12" sm="12" md="12" style="min-heigth:80px" v-for="(item, i) in certificados" :key="i">
                                        <v-sheet min-height="100%" width="100%">
                                            <v-container fluid class="pa-0">
                                                <v-row align="center" no-gutters class="text-center" style="min-height:80px">

                                                    <v-col cols="12" sm="12" md="12">
                                                        <p>
                                                            {{item.ciceCertificado}}
                                                        </p>
                                                    </v-col>

                                                </v-row>
                                            </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12" class="primary--text" v-if="dientesplaca.length > 0">
                        <h3>Control placa</h3>
                        <img width="800" id="imgplaca">
                        <div id="contenedorcanvasplaca">
                            <canvas id="canvasplaca"></canvas>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="primary--text" v-if="primerOdontograma.length > 0">
                        <h3>Primer odontograma</h3>
                        <img width="800" id="imgprimerodontograma">
                        <div id="contenedorcanvasprimerodontograma">
                            <canvas id="canvasprimerodontograma"></canvas>
                        </div>

                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="primary--text" v-if="dientesodontograma.length > 0">
                        <h3>Odontograma actual</h3>
                        <img width="800" id="imgodontograma">
                        <div id="contenedorcanvasodontograma">
                            <canvas id="canvasodontograma"></canvas>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </v-container>
        <v-container v-else-if="pdfh">
            <v-card-title class="text-h5 green lighten-2"> E-MAILS </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field outlined label="Correo" v-model="correo"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" id="Idbtn" :loading="loadingEmail" text @click="pdfEmail()">Enviar</v-btn>
                <v-btn color="primary" text @click="pdfh = null"> Cancelar </v-btn>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>

import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  documentos
} from "../utils/documentos";
import {
  fabric
} from "fabric";
import generales from '../mixins/generales.js';
export default {
  props: {
    item: Object
  },
  mixins: [generales],
  data() {
    return {
      menuFechaDesde:false,
      fechaDesde:new Date().addDays(-120).yyyymmdd(),
      loader: false,
      url: "sistema/historiasfacturaciones/",
      factura: null,
      tipocampos: [],
      datosPaciente: null,
      secciones: [],
      campos: [],
      Historia: [],
      historial: [],
      diagnosticos: [],
      ordenes: [],
      recetarios: [],
      remisiones: [],
      incapacidades: [],
      certificados: [],
      contratos: [],
      empresas: [],
      dientesplaca: [],
      primerOdontograma: [],
      dientesodontograma: [],
      vacunas: [],
      pesos: [],
      convenciones: [],
      canvasplaca: null,
      odontogramacanvas: null,
      primerodontogramacanvas: null,
      odontogramacargado: false,
      loadingEmail: false,
      historiaData: null,
      correo: null,
      pdfh: null
    };
  },
  created: async function() {
   await this.cargarDatos();
  },
  updated: async function() {
    if (!this.pdfh) {
      if (
        document.getElementById("canvasplaca") &&
        this.dientesodontograma != undefined
      ) {
        if (this.dientesodontograma && this.odontogramacargado == false) {
          this.odontogramacargado = true;
          if (this.dientesplaca.length > 0) {
            this.cargarplaca("#canvasplaca", this.dientesplaca);
          }
          if (this.primerOdontograma.length > 0) {
           this.primerodontogramacanvas = await this.cargarodontograma(
              "#canvasprimerodontograma",
              this.primerOdontograma
            );
          }
          if (this.dientesodontograma.length > 0) {
             this.odontogramacanvas = await this.cargarodontograma(
              "#canvasodontograma",
              this.dientesodontograma
            );
          }

          window.setTimeout(() => {
            if (this.dientesplaca.length > 0) {
              let imgcanvasplaca = document.getElementById("imgplaca");
              let canvasplaca = document.getElementById("contenedorcanvasplaca");
              imgcanvasplaca.removeAttribute("hidden");
              imgcanvasplaca.setAttribute(
                "src",
                this.canvasplaca.toDataURL("image/png")
              );
              canvasplaca.setAttribute("hidden", true);
            }
            if (this.primerOdontograma.length > 0) {
              let imgprimerodontogramacanvas = document.getElementById(
                "imgprimerodontograma"
              );
              let primerodontogramacanvas = document.getElementById(
                "contenedorcanvasprimerodontograma"
              );
              imgprimerodontogramacanvas.removeAttribute("hidden");
              imgprimerodontogramacanvas.setAttribute(
                "src",
                this.primerodontogramacanvas.toDataURL("image/png")
              );
              primerodontogramacanvas.setAttribute("hidden", true);
            }
            if (this.dientesodontograma.length > 0) {
              let imgodontogramacanvas = document.getElementById(
                "imgodontograma"
              );
              let odontogramacanvas = document.getElementById(
                "contenedorcanvasodontograma"
              );
              imgodontogramacanvas.removeAttribute("hidden");
              imgodontogramacanvas.setAttribute(
                "src",
                this.odontogramacanvas.toDataURL("image/png")
              );
              odontogramacanvas.setAttribute("hidden", true);
            }
          }, 1000);
        }
      }
    }

  },
  methods: {
    async cargarDatos(){
      try {
      this.convenciones = this.$config.convenciones;
      if (this.item != undefined) {
        this.loader = true;
        let resultados = await this.$apiService.index("sistema/historiasfacturaciones/generarPdf/" + this.item.citaId + "?historico="+this.item.historico+"&fechaDesde="+this.fechaDesde,this.token).then(data => data);
        if (!resultados.mensaje) {
          this.Historia = resultados.Historia;
          this.historial = resultados.historial.filter(e => e.tblConfHistoriaseccione);
          this.secciones = resultados.secciones;
          this.diagnosticos = resultados.diagnosticos;
          this.ordenes = resultados.ordenes;
          this.recetarios = resultados.recetarios;
          this.remisiones = resultados.remisiones;
          this.incapacidades = resultados.incapacidades;
          this.certificados = resultados.certificados;
          this.dientesplaca = resultados.dientesplaca;
          this.dientesodontograma = resultados.dientesodontograma;
          this.primerOdontograma = resultados.primerOdontograma;
          this.datosPaciente = resultados.datosPaciente;
          this.pesos = resultados.pesos;
          this.vacunas = resultados.vacunas;
          this.dataSource = resultados.dataSource;
          this.loader = false;
          let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
          if (data.mensaje == undefined && data.mensaje != 'No data') {
            this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
          } else {
            this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + this.datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
          }
          let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
          let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_HISTORIA').comaValor;
          this.datosPaciente.StrucDataPaciente = StrucDataPaciente;
          let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
      
        let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        this.datosPaciente.confOrdenes = confOrdenes;
        } else {
          this.loader = false;
          this.$swal.fire({
            title: "Historia no tramitada!",
            text: "primero debe ser llenado la historia",
            icon: "error"
          });
        }
      }
    } catch (error) {
      this.loader = false;
      console.log(error);
      this.$swal.fire({
        title: "Error!",
        text: "contacte con el administrador",
        icon: "error"
      });
    }
    },
    getValues(values, valor) {
      const lista = [];
      if (values) {
        var datos = values.split("|");
        for (let i = 0; i < datos.length; i++) {
          let d = datos[i];
          lista.push({
            texts: d.split(";")[0],
            values: d.split(";")[1]
          });
        }

        return lista.filter(e => e.values == valor).length > 0 ?
          lista.filter(e => e.values == valor)[0].texts :
          "";
      }
      return "";
    },
    async pdfEmail() {
      try {
        if (this.getPermiso("verHistorias")) {
          if (!this.correo) {
            this.$swal.fire({
              title: "Correo invalido!",
              text: "ingrese un correo valido",
              icon: "error"
            });
            return false;
          }
          if (this.correo.indexOf('@') < 0) {
            this.$swal.fire({
              title: "Correo invalido!",
              text: "ingrese un correo valido",
              icon: "error"
            });
            return false;
          }
          this.loadingEmail = true;
          this.historiaData = await this.pdf(true);
          let formData = new FormData();
          formData.append('historia', this.historiaData);
          if (this.diagnosticos.length > 0) {
            let pdfDiagnosticos = await this.pdfDiagnosticos();
            formData.append('pdfDiagnosticos', pdfDiagnosticos);
          }
          if (this.recetarios.length > 0) {
            let pdfRecetarios = await this.pdfRecetarios();
            formData.append('pdfRecetarios', pdfRecetarios);
          }
          if (this.ordenes.length > 0) {
            if(this.ordenes.some(e => e.orden.comaNombrecorto == 'TP_ORD_LAB')){
              let pdfOrdenesLab = await this.pdfOrdenes("LAB");
              formData.append('pdfOrdenesLab', pdfOrdenesLab);
            }
            if(this.ordenes.some(e => e.orden.comaNombrecorto == 'TP_ORD_IMG')){
              let pdfOrdenesImgDx = await this.pdfOrdenes("IMGDX");
              formData.append('pdfOrdenesImgDx', pdfOrdenesImgDx);
            }
            if(this.ordenes.some(e => e.orden.comaNombrecorto == 'TP_ORD_SER')){
              let pdfOrdenesServ = await this.pdfOrdenes("SERV");
              formData.append('pdfOrdenesServ', pdfOrdenesServ);
            }
            if(this.ordenes.some(e => e.orden.comaNombrecorto == 'TP_ORD_CIR')){
              let pdfOrdenesCir = await this.pdfOrdenes("CIR");
              formData.append('pdfOrdenesCir', pdfOrdenesCir);
            }
          }
          if (this.remisiones.length > 0) {
            let pdfRemiciones = await this.pdfRemiciones();
            formData.append('pdfRemiciones', pdfRemiciones);
          }
          if (this.incapacidades.length > 0) {
            let pdfIncapacidad = await this.pdfIncapacidad();
            formData.append('pdfIncapacidad', pdfIncapacidad);
          }
          if (this.certificados.length > 0) {
            let pdfCertificado = await this.pdfCertificado();
            formData.append('pdfCertificado', pdfCertificado);
          }
          let resultado = await this.$apiService.createFile("cita/citas/sendemail?correo=" + this.correo, formData).then(data => data);
          if (resultado == 'Correo enviado correctamente') {
            this.$swal.fire({
              title: "Completado!",
              text: "Envio exitoso",
              icon: "success",
              confirmButtonText: "Ok"
            });
          } else {
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
            });
          }
        }
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

      this.loadingEmail = false;
      this.pdfh = null;
    },
    async pdf(email = false) {
      if (typeof window !== "undefined") {
        let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
        let pdf = new jsPDF();
        let imagenesodontologia = {
          imgcanvasplaca: null,
          imgprimerodontogramacanvas: null,
          imgodontogramacanvas: null
        };
        if (this.dientesplaca.length > 0) {
          imagenesodontologia.imgcanvasplaca = document
            .getElementById("imgplaca")
            .getAttribute("src");
        }
        if (this.primerOdontograma.length > 0) {
          imagenesodontologia.imgprimerodontogramacanvas = document
            .getElementById("imgprimerodontograma")
            .getAttribute("src");
        }
        if (this.dientesodontograma.length > 0) {
          imagenesodontologia.imgodontogramacanvas = document
            .getElementById("imgodontograma")
            .getAttribute("src");
        }
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
        let configHistoria = '{}';  
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFHISTORIA')){
            configHistoria = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFHISTORIA').comaValor;
        }
        let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        this.datosPaciente.confOrdenes = confOrdenes;
        if (email) {
          this.loading = false;
          return await documento.getHistoria(
            pdf,
            {
              datosPaciente:this.datosPaciente,
              secciones:this.secciones,
              ordenes:this.ordenes,
              diagnosticos:this.diagnosticos,
              remisiones:this.remisiones,
              recetarios:this.recetarios,
              historial:this.historial,
              pesos:this.pesos,
              incapacidades:this.incapacidades,
              certificados:this.certificados,
              vacunas:this.vacunas,
              imagenesodontologia,
              cuerpo:false,
              email,
              configHistoria,
              dataSource:this.dataSource
            }
          );
        }
        documento.getHistoria(
          pdf,
          {
              datosPaciente:this.datosPaciente,
              secciones:this.secciones,
              ordenes:this.ordenes,
              diagnosticos:this.diagnosticos,
              remisiones:this.remisiones,
              recetarios:this.recetarios,
              historial:this.historial,
              pesos:this.pesos,
              incapacidades:this.incapacidades,
              certificados:this.certificados,
              vacunas:this.vacunas,
              imagenesodontologia,
              cuerpo:false,
              email,
              configHistoria,
              dataSource:this.dataSource
            }
        );
      }
    },
    async pdfDiagnosticos() {
      let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
      let pdf = new jsPDF();
      return documento.getDiagnosticos(pdf, this.diagnosticos, false, this.datosPaciente, 2, true);
    },
    async pdfRecetarios() {
      let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
      let pdf = new jsPDF();
      return documento.getRecetarios(pdf, this.recetarios, false, this.datosPaciente, 2, this.diagnosticos, true);
    },
    async pdfOrdenes(tipo) {
      let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
      let pdf = new jsPDF();
      if(tipo == "LAB"){
        return documento.getOrdenesLaboratorios(pdf, this.ordenes, false, this.datosPaciente, 2, this.diagnosticos, true);
      }
      if(tipo == "IMGDX"){
        return documento.getOrdenesImagenDiag(pdf, this.ordenes, false, this.datosPaciente, 2, this.diagnosticos, true);
      }
      if(tipo == "SERV"){
        return  documento.getOrdenesServicios(pdf, this.ordenes, false, this.datosPaciente, 2, this.diagnosticos, true);
      }
      if(tipo == "CIR"){
        return  documento.getOrdenesCirujia(pdf, this.ordenes, false, this.datosPaciente, 2, this.diagnosticos, true);
      }
    },
    async pdfRemiciones() {
      let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
      let pdf = new jsPDF();
      return documento.getRemisiones(pdf, this.remisiones, false, this.datosPaciente, 2, this.diagnosticos, true);
    },
    async pdfIncapacidad() {
      let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
      let pdf = new jsPDF();
      return documento.getIncapacidades(pdf, this.incapacidades, false, this.datosPaciente, 2, this.diagnosticos, true);
    },
    async pdfCertificado() {
      let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
      let pdf = new jsPDF();
      return documento.getCertificados(pdf, this.certificados, false, this.datosPaciente, 2, this.diagnosticos, true);
    },
    async cargarodontograma(nombre, dientesplaca) {
            return new Promise((resolve)=>{
                const canvas = new fabric.Canvas(document.querySelector(nombre), {
                height: 500,
                width: 1200,
                selectionColor: "#90ccb7",
                backgroundColor: "#fff"
            });
            let cant = 1
            const total = dientesplaca.length
            for (const diente of dientesplaca) {
                const text = new fabric.Text(diente.numero + "", {
                    left: diente.partes.parteRightTop.left - 8,
                    top: diente.partes.parteRightTop.top - 40,
                    fontSize: 12
                });
                canvas.add(text);
                const rect1 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftTop.left,
                    top: diente.partes.parteLeftTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteLeftTop.backgroundColor,
                    id: diente.partes.parteLeftTop.id
                });
                const rect2 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightTop.left,
                    top: diente.partes.parteRightTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteRightTop.backgroundColor,
                    id: diente.partes.parteRightTop.id
                });
                const rect3 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftBottom.left,
                    top: diente.partes.parteLeftBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteLeftBottom.backgroundColor,
                    id: diente.partes.parteLeftBottom.id
                });
                const rect4 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightBottom.left,
                    top: diente.partes.parteRightBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteRightBottom.backgroundColor,
                    id: diente.partes.parteRightBottom.id
                });
                const circulo = new fabric.Circle({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    stroke: "#000000",
                    selectable: false,
                    absolutePositioned: true,
                    radius: 12,
                    left: diente.partes.parteMedio.left,
                    top: diente.partes.parteMedio.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteMedio.backgroundColor,
                    id: diente.partes.parteMedio.id
                });
                const rect6 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    selectable: false,
                    stroke: "#000000",
                    width: 30,
                    height: 30,
                    left: diente.partes.parteTop.left,
                    top: diente.partes.parteTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteTop.backgroundColor,
                    id: diente.partes.parteTop.id
                });
                const rect7 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    selectable: false,
                    stroke: "#000000",
                    width: 30,
                    height: 30,
                    left: diente.partes.parteBottom.left,
                    top: diente.partes.parteBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteBottom.backgroundColor,
                    id: diente.partes.parteBottom.id
                });
                canvas.add(rect6);
                canvas.add(rect7);
                canvas.add(rect1);
                canvas.add(rect2);
                canvas.add(rect3);
                canvas.add(rect4);
                canvas.add(circulo);
                if (diente.convencion) {
                    const img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.convencion
                    ).img;
                    const convencion = new fabric.Image(img, {
                        left: diente.partes.parteMedio.left - 38,
                        top: diente.partes.parteMedio.top - 38,
                        scaleX: 0.15,
                        scaleY: 0.15,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(convencion).renderAll();
                    canvas.setActiveObject(convencion);
                }

                if (diente.partes.parteTop.convencion) {
                    const img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteTop.convencion
                    ).img;
                    const parteTop = new fabric.Image(img, {
                        left: diente.partes.parteTop.left - 12,
                        top: diente.partes.parteTop.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteTop).renderAll();
                    canvas.setActiveObject(parteTop);
                }
                if (diente.partes.parteBottom.convencion) {
                    const img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteBottom.convencion
                    ).img;
                    const parteBottom = new fabric.Image(img, {
                        left: diente.partes.parteBottom.left - 12,
                        top: diente.partes.parteBottom.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteBottom).renderAll();
                    canvas.setActiveObject(parteBottom);
                }
                if (diente.partes.parteLeftTop.convencion) {
                    const img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteLeftTop.convencion
                    ).img;
                    const parteLeftTop = new fabric.Image(img, {
                        left: diente.partes.parteLeftTop.left - 12,
                        top: diente.partes.parteLeftTop.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteLeftTop).renderAll();
                    canvas.setActiveObject(parteLeftTop);
                }
                if (diente.partes.parteRightTop.convencion) {
                    const img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteRightTop.convencion
                    ).img;
                    const parteRightTop = new fabric.Image(img, {
                        left: diente.partes.parteRightTop.left - 12,
                        top: diente.partes.parteRightTop.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteRightTop).renderAll();
                    canvas.setActiveObject(parteRightTop);
                }
                if (diente.partes.parteLeftBottom.convencion) {
                    const img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteLeftBottom.convencion
                    ).img;
                    const parteLeftBottom = new fabric.Image(img, {
                        left: diente.partes.parteLeftBottom.left - 12,
                        top: diente.partes.parteLeftBottom.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteLeftBottom).renderAll();
                    canvas.setActiveObject(parteLeftBottom);
                }
                if (diente.partes.parteRightBottom.convencion) {
                    const img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteRightBottom.convencion
                    ).img;
                    const parteRightBottom = new fabric.Image(img, {
                        left: diente.partes.parteRightBottom.left - 12,
                        top: diente.partes.parteRightBottom.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteRightBottom).renderAll();
                    canvas.setActiveObject(parteRightBottom);
                }
                if (diente.partes.parteMedio.convencion) {
                    const img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteMedio.convencion
                    ).img;
                    const parteMedio = new fabric.Image(img, {
                        left: diente.partes.parteMedio.left - 12,
                        top: diente.partes.parteMedio.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteMedio).renderAll();
                    canvas.setActiveObject(parteMedio);
                }
                //canvas.renderAll();
                if(cant == total){
                   resolve(canvas)
                }
                cant= cant+1
            }
           })
        },
    cargarplaca(nombre, dientesplaca) {
      this.canvasplaca = new fabric.Canvas(document.querySelector(nombre), {
        height: 500,
        width: 1200,
        selectionColor: "#90ccb7",
        backgroundColor: "#fff"
      });

      dientesplaca.forEach(diente => {
        var text = new fabric.Text(diente.numero + "", {
          left: diente.partes.parteRightTop.left - 10,
          top: diente.partes.parteRightTop.top - 35,
          fontSize: 12
        });
        this.canvasplaca.add(text);
        var rect1 = new fabric.Rect({
          hoverCursor: "pointer",
          originX: "center",
          originY: "center",
          angle: 45,
          selectable: false,
          stroke: "#000000",
          width: 20,
          height: 20,
          left: diente.partes.parteLeftTop.left,
          top: diente.partes.parteLeftTop.top,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          lockSkewingX: true,
          lockSkewingY: true,
          fill: diente.partes.parteLeftTop.placa ?
            "#FF0000" : diente.partes.parteLeftTop.backgroundColor,
          id: diente.partes.parteLeftTop.id
        });
        var rect2 = new fabric.Rect({
          hoverCursor: "pointer",
          originX: "center",
          originY: "center",
          angle: 45,
          selectable: false,
          stroke: "#000000",
          width: 20,
          height: 20,
          left: diente.partes.parteRightTop.left,
          top: diente.partes.parteRightTop.top,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          lockSkewingX: true,
          lockSkewingY: true,
          fill: diente.partes.parteRightTop.placa ?
            "#FF0000" : diente.partes.parteRightTop.backgroundColor,
          id: diente.partes.parteRightTop.id
        });
        var rect3 = new fabric.Rect({
          hoverCursor: "pointer",
          originX: "center",
          originY: "center",
          angle: 45,
          selectable: false,
          stroke: "#000000",
          width: 20,
          height: 20,
          left: diente.partes.parteLeftBottom.left,
          top: diente.partes.parteLeftBottom.top,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          lockSkewingX: true,
          lockSkewingY: true,
          fill: diente.partes.parteLeftBottom.placa ?
            "#FF0000" : diente.partes.parteLeftBottom.backgroundColor,
          id: diente.partes.parteLeftBottom.id
        });
        var rect4 = new fabric.Rect({
          hoverCursor: "pointer",
          originX: "center",
          originY: "center",
          angle: 45,
          selectable: false,
          stroke: "#000000",
          width: 20,
          height: 20,
          left: diente.partes.parteRightBottom.left,
          top: diente.partes.parteRightBottom.top,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          lockSkewingX: true,
          lockSkewingY: true,
          fill: diente.partes.parteRightBottom.placa ?
            "#FF0000" : diente.partes.parteRightBottom.backgroundColor,
          id: diente.partes.parteRightBottom.id
        });
        var circulo = new fabric.Circle({
          hoverCursor: "pointer",
          originX: "center",
          originY: "center",
          stroke: "#000000",
          selectable: false,
          absolutePositioned: true,
          radius: 12,
          left: diente.partes.parteMedio.left,
          top: diente.partes.parteMedio.top,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          lockSkewingX: true,
          lockSkewingY: true,
          fill: diente.partes.parteMedio.placa ?
            "#FF0000" : diente.partes.parteMedio.backgroundColor,
          id: diente.partes.parteMedio.id
        });
        this.canvasplaca.add(rect1);
        this.canvasplaca.add(rect2);
        this.canvasplaca.add(rect3);
        this.canvasplaca.add(rect4);
        this.canvasplaca.add(circulo);
      });
    },

  }
};

</script>

<style>
</style>
